h1 {
  font-size: initial;
}

.landing {
  position: relative;
  background: var(--color-gray-0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.dots {
  display: flex;
  flex-direction: column;
  z-index: 401;
  justify-items: center;
  align-items: center;
  position: relative;
}
.dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: var(--color-gray-0);
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.74, 0.14, 0.27, 0.81);
  position: absolute;
}
.dot:hover {
  transform: scale(2);
  border: 2px solid var(--color-gray-0);
  box-shadow: 0px 3px 6px #00000029;
}
.dot:nth-child(1) {
  top: -14rem;
}
.dot:nth-child(2) {
  top: -10rem;
}
.dot:nth-child(3) {
  top: -6rem;
}
.dot:nth-child(4) {
  top: -2rem;
}
.dot:nth-child(5) {
  top: 2rem;
}
.dot:nth-child(6) {
  top: 6rem;
}
.dot:nth-child(7) {
  top: 10rem;
}
.dot:nth-child(8) {
  top: 14rem;
}
.dot.active {
  transform: scale(1.6);
  border: 2px solid var(--color-gray-0);
  box-shadow: 0px 3px 6px #00000029;
  animation: 0.75s dotshrink cubic-bezier(0.74, 0.14, 0.27, 0.81);
}
.dot.active::after {
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  animation: pulsate 1.5s ease-out infinite;
  border: 2px solid var(--color-gray-0);
  opacity: 0;
}
@keyframes dotshrink {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1.6);
  }
}
@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.6, 1.6);
    opacity: 0;
  }
}
.landing .content-block {
  max-width: 26vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.left {
  position: absolute;
  left: 0;
  top: 100%;
  width: 50%;
  height: 100%;
  display: flex;
  padding: 88px 0;
  z-index: 2;
}
.left.one {
  /* background: var(--color-gray-new-0); */
  background: var(--color-green-2);
}
.left.one .titles {
  display: flex;
  flex-direction: column;
}
.left.one .title {
  color: var(--color-gray-0);
  white-space: pre-wrap;
}
.left.one .title.new {
  margin-right: auto;
  padding: 0 1vw;
}
.left.one .title.new.inverse {
  background: var(--color-gray-0);
  color: var(--color-green-2);
  white-space: pre-wrap;
  margin-top: 1rem;
}
.left.one .title.new.date-enrollment {
  color: #3e6171;
  white-space: pre-wrap;
  /* margin-top: 1rem; */
}
.left.one .btn-enrollment {
  margin-left: 1vw;
  margin-top: 48px;
  color: #3fc1b3;
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 64px;
  padding: 13px 24px;
}
.left.one .size-20 {
  white-space: pre-wrap;
  margin: 5.6vh 0;
  line-height: 1.5rem;
}
.left.one .sub-title {
  color: var(--color-gray-new-1);
  white-space: nowrap;
  margin-top: 6.8vh;
  line-height: 3.8vh;
}
.left.one .stamp-box {
  display: flex;
  margin-left: 19vw;
  width: 5.833vw;
  height: 5.833vw;
  position: relative;
  margin-top: 2vh;
}
.left.one .stamp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* animation: 14s infinite linear spin; */
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.left.one .mask {
  position: absolute;
  background-image: url("../img/icons/stamp.svg");
  background-size: 5.833vw;
  background-position: center;
  background-color: var(--color-gray-new-0);
  top: 1vw;
  left: 1vw;
  border-radius: 1vw;
  z-index: 2;
  width: 3.833vw;
  height: 3.833vw;
}
.left.one .double-arrow-down {
  position: absolute;
  bottom: 13vh;
  left: 13vw;
  animation: sdb04 2s infinite;
  cursor: pointer;
  width: 2.292vw;
  height: 2.292vw;
}
@keyframes sdb04 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, 2vh);
  }
  40% {
    transform: translate(0, 0);
  }
}
.left.one .double-arrow-down .cls-1:first-child {
  display: none;
}
.left.one .double-arrow-down .cls-1 {
  fill: var(--color-gray-new-0);
  stroke-width: 0.25rem;
  stroke: var(--color-gray-3);
}
.left.one .double-arrow-down .cls-2 {
  stroke: unset;
}
.left.one .double-arrow-down + .thin {
  position: absolute;
  font-size: 0.625vw;
  bottom: 10vh;
  left: 12vw;
  display: none;
}
.left.two {
  background: var(--color-pink-0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left.two.active {
  animation: scrolldown 500ms ease-in-out forwards, fadeIn ease 3s;
}
.left.two .text-box {
  display: flex;
  flex-direction: column;
  min-width: 27.604vw;
  max-width: 27.604vw;
}
.left.two .text {
  color: var(--color-gray-0);
}
.left.two.active .text {
  color: var(--color-gray-0);
  white-space: pre-wrap;
  width: 0px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  animation: typewriter 0.5s steps(100, end);
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.left.two.active .text:nth-child(2) {
  animation-delay: 0.75s;
}
.left.two.active .text:nth-child(3) {
  animation-delay: 1s;
}
.left.two.active .text:nth-child(4) {
  animation-delay: 1.25s;
}
.left.two.active .text:nth-child(5) {
  animation-delay: 1.5s;
}
.left.two.active .text:nth-child(6) {
  animation-delay: 1.75s;
}
.left.two.active .text:nth-child(7) {
  animation-delay: 2s;
}
.left.two.active .text:nth-child(9) {
  animation-delay: 2.25s;
}
@keyframes typewriter {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
.left.two.active .text.thin {
  color: var(--color-gray-new-1);
  margin-top: 1rem;
}
.left.two.active .separator {
  animation: sepa-grow 0.75s steps(100, end);
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
  margin-top: 5.25rem;
  height: 6px;
  width: 0px;
  background: var(--color-gray-new-1);
}
@keyframes sepa-grow {
  from {
    width: 0px;
  }
  to {
    width: 3.5vw;
  }
}
.left.three {
  background: var(--color-green-2);
}
.left.three *,
.left.six * {
  color: var(--color-gray-0);
}
.left.three .title,
.left.four .title,
.left.five .title,
.left.six .title,
.left.seven .title,
.left.eight .title {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.left.three :not(.title),
.left.four :not(.title),
.left.five :not(.title),
.left.six :not(.title),
.left.seven :not(.title),
.left.eight :not(.title) {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}
.left.three.active .title,
.left.four.active .title,
.left.five.active .title,
.left.six.active .title,
.left.seven.active .title,
.left.eight.active .title {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.left.three.active :not(.title),
.left.four.active :not(.title),
.left.five.active :not(.title),
.left.six.active :not(.title),
.left.seven.active :not(.title),
.left.eight.active :not(.title) {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}
.left.three .article:nth-child(2)::first-letter {
  font-size: 2.813vw;
  font-weight: 800;
}
.left.three .inline-link {
  cursor: pointer;
  text-decoration: underline;
}
.left.three .content-block,
.left.six .content-block,
.left.seven .content-block {
  max-width: 26vw;
}
.left.three .article:nth-child(2),
.left.six .article:nth-child(2) {
  font-weight: 100;
  margin-top: 6.4vh;
  white-space: pre-wrap;
}
.left.six .article:nth-child(2)::first-letter {
  font-size: 2.813vw;
  font-weight: 800;
  color: var(--color-gray-new-1);
}
.left.three .article,
.left.six .article {
  line-height: 26px;
}
.left.three .article + .article,
.left.six .article + .article {
  margin-top: 2rem;
}
.left.three .button-box {
  margin-top: 6.4vh;
}
.left.one .btn,
.left.three .button-box .btn {
  padding: 1vh 1.302vw;
  font-size: 0.781vw;
  font-weight: 800;
  transition: border-color 300ms, background-color 300ms, color 300ms;
  cursor: pointer;
}
.left.one .btn {
  padding: 15px 50px;
}
.left.one .btn:focus,
.left.three .button-box .btn:focus {
  outline: unset;
}
.left.three .button-box a + .btn-programs {
  margin-left: 15px;
}
.left.three .button-box .btn-primary {
  background: transparent;
  color: var(--color-btn-normal-green-2);
  border: 2px solid var(--color-btn-normal-green-2);
}
.left.three .button-box .btn-primary:hover {
  color: var(--color-btn-hover-green-2);
  border: 2px solid var(--color-btn-hover-green-2);
}
.left.three .button-box .btn-primary:active {
  color: var(--color-btn-active-green-2);
  border: 2px solid var(--color-btn-active-green-2);
}
.left.one .btn-secondary,
.left.three .button-box .btn-secondary {
  color: var(--color-btn-normal-green);
  background: var(--color-btn-normal-green-2);
  border: 2px solid var(--color-btn-normal-green-2);
}
.left.one .btn-secondary:hover,
.left.three .button-box .btn-secondary:hover {
  background: var(--color-btn-hover-green-2);
  border: 2px solid var(--color-btn-hover-green-2);
}
.left.one .btn-secondary:active,
.left.three .button-box .btn-secondary:active {
  background: var(--color-btn-active-green-2);
  border: 2px solid var(--color-btn-active-green-2);
}
.left.four {
  background: var(--color-gray-3);
}
.left.four .content-block {
  max-width: 26vw;
}
.left.four .content-block * {
  color: var(--color-gray-new-1);
}
.left.four .drop-down-list {
  margin-top: 3.4vh;
}
.left.four .drop-down-list,
.left.four .drop-down-list .place,
.left.four .drop-down-list .place .place-informations {
  display: flex;
  flex-direction: column;
}
.left.four .drop-down-list .place {
  padding: 0.85vh 0;
  padding-left: 3rem;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.left.four .drop-down-list .place * {
  cursor: pointer;
}
.left.four .drop-down-list .place .plus-sign,
.left.four .drop-down-list .place .minus-sign {
  position: absolute;
  left: 1rem;
  top: 0.375rem;
  width: 1.5rem;
  height: 3.4vh;
  display: block;
}
.left.four .drop-down-list .place .plus-sign {
  top: 0;
  height: 100%;
}
.left.four .drop-down-list .place .minus-sign {
  display: none;
}
.left.four .drop-down-list .place.active .minus-sign {
  display: block;
}
.left.four .drop-down-list .place.active .plus-sign {
  display: none;
}
.left.four .drop-down-list .place .plus-sign .cls-1,
.left.four .drop-down-list .place .plus-sign .cls-1,
.left.four .drop-down-list .place .minus-sign .cls-2,
.left.four .drop-down-list .place .minus-sign .cls-2 {
  stroke: var(--color-gray-new-1);
  stroke-width: 0.125rem;
}
.left.four.active ~ .dots .dot {
  background: var(--color-gray-new-1);
}
.left.four.active ~ .dots .dot.active {
  border-color: var(--color-gray-new-1);
}
.left.four.active ~ .dots .dot.active::after {
  border-color: var(--color-gray-new-1);
}
.left.four.active ~ .dots .dot:hover,
.left.four.active ~ .dots .dot.active:hover {
  border: 2px solid var(--color-gray-new-1);
}
.left.four .drop-down-list .place .place-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.left.four .drop-down-list .place .place-informations {
  max-height: 0;
  overflow: hidden;
}
.left.four .drop-down-list .place .place-address {
  margin-top: 1.06vh;
  margin-bottom: 1.7vh;
}
.left.four .drop-down-list .place .title {
  margin-top: 0.423vh;
}
.left.four .drop-down-list .place.active .title:before {
  content: "•";
  position: absolute;
  left: 35px;
}
.left.four .drop-down-list .place.active .place-informations {
  max-height: 30rem;
  transition: max-height 0.3s ease-in-out;
}
.left.five {
  background: var(--color-gray-3);
}
.left.five .title,
.left.seven .title {
  color: var(--color-gray-7);
  margin-bottom: 2rem;
}
.left.five .list-item,
.left.seven .list-item {
  color: var(--color-gray-7);
  padding: 0.417vw 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
}
.left.seven .list-item {
  color: var(--color-gray-new-1);
}
.left.five.active .list-item,
.left.seven.active .list-item {
  width: 2.292vw;
  animation: typing 2s steps(100, end);
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transition: transform 0.3s ease-in-out;
}
@keyframes typing {
  from {
    width: 2.292vw;
  }
  to {
    width: 100%;
  }
}
.left.five.active .list-item.active,
.left.seven.active .list-item.active {
  transition-delay: 2.3s;
  transform: translateX(2.083vw);
}
.left.five.active .list-item:hover,
.left.seven.active .list-item:hover,
.left.five.active .list-item.active:hover,
.left.seven.active .list-item.active:hover {
  transition-delay: 0s;
  transform: translateX(2.083vw);
}
.left.five .list-item .arrow,
.left.seven .list-item .arrow {
  margin-right: 0.833vw;
}
.left.five .list-item .arrow,
.left.seven .list-item .arrow {
  min-width: 1.458vw;
  max-width: 1.458vw;
}
.left.five.active .list-item .arrow,
.left.seven.active .list-item .arrow {
  margin-right: 0.833vw;
  animation: grow linear 1s;
}
@keyframes grow {
  0% {
    transform: scale(0.2);
    transform-origin: top left;
  }
  100% {
    transform: scale(1);
    transform-origin: top left;
  }
}
.left.five .list-item .arrow .cls-1,
.left.seven .list-item .arrow .cls-1 {
  fill: transparent;
  stroke-width: 4px;
  stroke: var(--color-gray-new-1);
}
.left.seven .list-item .arrow .cls-1 {
  stroke: var(--color-gray-0);
}
.left.five .list-item .arrow .cls-2,
.left.seven .list-item .arrow .cls-2 {
  stroke-width: 4px;
  stroke: var(--color-gray-new-1);
}
.left.seven .list-item .arrow .cls-2 {
  stroke: var(--color-gray-0);
}
.left.six {
  background: var(--color-blue-4);
}
.left.six .content-block .title {
  white-space: pre-wrap;
}
.left.seven {
  background: var(--color-green-0);
}
.left.eight {
  background: var(--color-pink-0);
}
.left.eight * {
  color: var(--color-gray-0);
}
.left.eight .content-block {
  max-width: 26vw;
}
.left.eight .title {
  color: var(--color-gray-0);
  margin-bottom: 2.5vh;
}
.left.eight .article {
  line-height: 2.9vh;
}
.left.eight .sepa-row {
  display: flex;
  align-items: center;
  margin: 1.5vh 0;
  margin-left: 6vw;
}
.left.eight .sepa-row .icon {
  min-width: 2.083vw;
  max-height: 4.5vh;
}
.left.eight .sepa-row .text {
  margin: 0 1vw;
  white-space: nowrap;
  color: var(--color-gray-0);
}
.left.eight .sepa-row hr {
  margin: 0;
  width: 100%;
  border: 0;
  border-top: 1px solid var(--color-gray-0);
}
.left.active {
  animation: scrollup 500ms ease-in-out forwards;
}
.left:not(.active) {
  animation: scrolloutdown ease-in-out 500ms;
  overflow: hidden;
}
.right {
  position: absolute;
  top: -100%;
  left: 50%;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
}
.right.one {
  /* background-image: url("../img/landing_bg_1.jpg"); */
  background-image: url("../img/muveszitorna-balett-moderntanc.png");
}
/* .right.two {

} */
.right.two.active {
  animation: scrollup 500ms ease-in-out forwards, fadeIn ease 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.right.three {
  background-image: url("../img/landing_bg_3.jpg");
}
.right.four {
  background: var(--color-gray-3);
  overflow: hidden;
}
.right.five,
.right.seven {
  transition: background-image 0.3s ease-in-out, transform 0.5s ease-in-out;
}
.right.active.five.zoomed,
.right.active.seven.zoomed {
  transform: scale(1.5);
}
.right.five.no-one {
  background-image: url("../img/landing_sub/five-one.jpg");
}
.right.five.no-two {
  background-image: url("../img/landing_sub/five-two.jpg");
}
.right.five.no-three {
  background-image: url("../img/landing_sub/five-three.jpg");
}
.right.five.no-four {
  background-image: url("../img/landing_sub/five-four.jpg");
}
.right.five.no-five {
  background-image: url("../img/landing_sub/five-five.jpg");
}
.right.five.no-fix {
  background-image: url("../img/landing_sub/five-six.jpg");
}
.right.five.no-seven {
  background-image: url("../img/landing_sub/five-seven.jpg");
}
.right.six {
  background: var(--color-blue-3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.right.six .content-block {
  margin-left: auto;
  max-width: unset;
}
.right.six .content-block iframe {
  margin-top: calc(9vh + 3.75rem);
  width: 32.344vw;
  height: 32vh;
}
.right.six .content-block .link-block {
  margin-top: 3rem;
  background: var(--color-blue-3);
  display: flex;
  justify-content: center;
}
.right.six .content-block .link-block .link {
  cursor: pointer;
  color: var(--color-gray-0);
  opacity: 0.45;
}
.right.six .content-block .link-block .link + .link {
  margin-left: 1rem;
}
.right.six .content-block .link-block .link.active {
  font-weight: 600;
  opacity: 1;
}
.right.seven.no-one {
  background-image: url("../img/landing_sub/seven-one.jpg");
}
.right.seven.no-two {
  background-image: url("../img/landing_sub/seven-two.jpg");
}
.right.seven.no-three {
  background-image: url("../img/landing_sub/seven-three.jpg");
}
.right.seven.no-four {
  background-image: url("../img/landing_sub/seven-four.jpg");
}
.right.seven.no-five {
  background-image: url("../img/landing_sub/seven-five.jpg");
}
.right.seven.no-fix {
  background-image: url("../img/landing_sub/seven-six.jpg");
}
.right.eight {
  background-image: url("../img/landing_bg_8.jpg");
}
.right.active {
  animation: scrolldown 500ms ease-in-out forwards;
}
.right:not(.active) {
  animation: scrolloutup 500ms ease-in-out;
}

.social-media-bar {
  position: fixed;
  right: 66px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 401;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.social-media-bar .icon {
  cursor: pointer;
}
.social-media-bar .icon + .icon {
  margin-top: 25px;
}

.social-media-bar .icon .cls-1 {
  fill: var(--color-gray-0);
}

.social-media-bar .icon .cls-1,
.social-media-bar .icon .cls-2 {
  stroke: unset;
}

.mobile-card,
.mobile-floating,
.mobile-footer {
  display: none;
}

@keyframes scrolldown {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@keyframes scrollup {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes scrolloutdown {
  from {
    top: 0%;
  }
  to {
    top: -100%;
  }
}

@keyframes scrolloutup {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}

@media screen and (max-width: 767px) {
  .landing {
    overflow: scroll;
    flex-direction: column;
    height: unset;
  }
  .left {
    padding-top: 0;
    padding-bottom: 0;
  }
  .right.active,
  .left.active,
  .right:not(.active),
  .left:not(.active) {
    animation: unset;
  }
  .left.one {
    order: 2;
    padding: 1.5rem 1.75rem;
    position: relative;
  }
  .left.one .content-block {
    margin-left: unset;
  }
  .left.one .titles {
    /* display: flex;
    flex-direction: row;
    align-items: center; */
  }
  .left.one .titles h1:nth-last-child() {
    margin-top: 0;
    /* display: flex;
    flex-direction: row;
    align-items: center; */
  }
  .left.one .title {
    font-size: 38px;
  }
  .left.one .title.new {
    font-size: 6vw;
  }
  .left.one .title.new:first-child {
    padding-left: 0;
  }
  .left.one .title.new.inverse {
    margin-top: 0;
  }
  .left.one .size-20 {
    margin: 19px 0;
    line-height: 17px;
  }
  .left.one .sub-title {
    margin-top: 1rem;
  }
  .left.one .content-block .stamp-box {
    width: 3.5rem;
    height: 3.5rem;
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    margin-top: unset;
  }
  .left.one .content-block .stamp-box .a {
    fill: var(--color-gray-7);
  }
  .left.one .content-block .stamp-box .mask {
    display: none;
    width: 4.5rem;
    height: 4.5rem;
    top: 1.25rem;
    left: 1.25rem;
    border-radius: 3.75rem;
    background-size: 7rem;
    background-image: url("../img/icons/stamp_inverse.svg");
  }
  .right.one {
    order: 1;
    background-size: 100% auto;
    /* background-color: var(--color-gray-new-0); */
    background-color: var(--color-green-2);
    background-position: top;
    top: 0;
    height: 50vh;
  }
  .right.one-mobile {
    order: 1;
    background-size: 100% auto;
    /* background-color: var(--color-gray-new-0); */
    background-color: var(--color-green-2);
    background-position: top;
    top: 0;
    height: 50vh;
    background-image: url("../img/muveszitorna-balett-moderntanc-mobile.png");
  }
  .mobile-card.one {
    order: 3;
    background: var(--color-pink-0);
    filter: contrast(80%);
  }
  .left.two {
    order: 3;
  }
  .left.two.active {
    animation: unset;
  }
  .left.two.active .text {
    animation: unset;
    width: 100%;
  }
  .right.two.active,
  .right.two {
    order: 4;
    background-size: 80% auto;
    background-position: center 0;
    background-color: var(--color-pink-0);
    animation: unset;
    height: 30rem;
  }
  .mobile-card.two {
    order: 5;
    background: var(--color-green-2);
    filter: contrast(80%);
  }
  .left.three {
    order: 5;
    padding: 1.75rem 3rem;
  }
  .right.three {
    order: 6;
    background-size: 80% auto;
    background-position: center 0;
    background-color: var(--color-green-0);
    background-image: url("../img/landing_bg_3.jpg");
    animation: unset;
    height: 30rem;
  }
  .left.three .article,
  .left.three .article:nth-child(2) {
    margin-top: 0;
  }
  .left.three .article:nth-child(2)::first-letter {
    font-size: 32px;
    color: var(--color-gray-new-1);
  }
  .left.three .article:nth-child(3) {
    font-size: 15px;
  }
  .left.three .button-box {
    margin-top: 2rem;
    margin-bottom: 0.875rem;
  }
  .left.one .btn,
  .left.three .button-box .btn {
    padding: 10px 22px;
    font-size: 12px;
  }
  .mobile-card.three {
    order: 14;
    background: var(--color-gray-3);
    filter: contrast(80%);
  }
  .left.four {
    padding: 1.75rem;
    padding-bottom: 3rem;
    order: 14;
    background: var(--color-gray-2);
  }
  .left.four .drop-down-list {
    margin-top: 0;
  }
  .left.four .drop-down-list .place {
    padding: 1rem 0;
    padding-left: 3rem;
  }
  .left.four .drop-down-list .place .place-address {
    margin-bottom: 0;
  }
  .left.four .drop-down-list .place .place-informations * {
    font-size: 14px;
    font-weight: 100;
  }
  .left.four .drop-down-list .place .place-informations .title {
    display: block;
    text-decoration: underline;
    margin-top: 1rem;
  }
  .left.four .drop-down-list .place .place-informations .title::before {
    display: none;
  }
  .left.four .drop-down-list .place .plus-sign,
  .left.four .drop-down-list .place .minus-sign {
    left: 0.75rem;
    top: 0.825rem;
    height: 3.4vh;
  }
  .mobile-card.four {
    order: 8;
    background: var(--color-gray-3);
    filter: contrast(80%);
  }
  .left.five {
    background: var(--color-gray-3);
    order: 8;
    padding: 1.75rem 4rem;
    padding-top: 2rem;
  }
  .left.five .content-block {
    margin: 0;
  }
  .left.five.active .list-item .arrow,
  .left.seven.active .list-item .arrow {
    animation: unset;
  }
  .left.five.active .list-item.active,
  .left.seven.active .list-item.active {
    transition: unset;
    transform: unset;
  }
  .left.five.active .list-item:hover,
  .left.seven.active .list-item:hover,
  .left.five.active .list-item.active:hover,
  .left.seven.active .list-item.active:hover {
    transform: unset;
  }
  .left.five.active .list-item,
  .left.seven.active .list-item {
    animation: unset;
    width: 100%;
  }
  .left.five .list-item .text,
  .left.seven .list-item .text {
    font-size: 16px;
  }
  .right.five {
    order: 9;
    background-size: 80% auto;
    background-position: center 0;
    background-color: var(--color-gray-3);
    background-image: url("../img/courses/muveszi_torna.jpg") !important;
    animation: unset;
    height: 20rem;
  }
  .mobile-card.five {
    order: 10;
    background: var(--color-blue-4);
    filter: contrast(80%);
  }
  .left.six {
    order: 10;
    padding: 1.75rem 3.5rem;
  }
  .left.three .content-block,
  .left.six .content-block {
    max-width: 20rem;
  }
  .left.six .article:nth-child(2) {
    margin-top: 0;
  }
  .left.six .article:nth-child(2)::first-letter {
    font-size: 32px;
    color: var(--color-gray-new-1);
  }
  .right.six {
    order: 11;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: center 0;
    background-color: var(--color-blue-4);
    background-image: url("../img/landing_mobile_bg_6.jpg");
    animation: unset;
    height: 20rem;
  }
  .mobile-card.six {
    order: 12;
    background: var(--color-green-0);
    filter: contrast(80%);
  }
  .left.seven {
    order: 12;
    padding: 1.75rem 4rem;
    padding-top: 2rem;
  }
  .left.seven .content-block {
    margin: 0;
    max-width: unset;
  }
  .left.five .list-item,
  .left.seven .list-item {
    color: var(--color-gray-7);
    padding: 0;
  }
  .left.five .list-item .arrow,
  .left.seven .list-item .arrow {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    margin-right: 1rem;
  }
  .left.five .list-item .arrow .cls-1 {
    fill: var(--color-gray-7);
    stroke: var(--color-gray-7);
    stroke-width: 0.3px;
  }
  .left.seven .list-item .arrow .cls-1 {
    fill: var(--color-gray-0);
    stroke: var(--color-gray-0);
    stroke-width: 0.3px;
  }
  .left.five .list-item .arrow .cls-2 {
    stroke: var(--color-gray-7);
  }
  .left.seven .list-item .arrow .cls-2 {
    stroke: var(--color-gray-0);
  }
  .right.seven {
    order: 13;
    background-color: var(--color-green-0);
    background-image: url("../img/landing_sub/seven-one.jpg") !important;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: center 0;
    height: 30rem;
  }
  .mobile-card.seven {
    order: 7;
    background: var(--color-pink-0);
    filter: contrast(80%);
  }
  .left.eight {
    order: 7;
    padding: 0.5rem 3.5rem 3.5rem 3.5rem;
  }
  .left.eight .content-block {
    max-width: 20rem;
  }
  .left.eight .sepa-row {
    margin-top: 1.75rem;
    margin-bottom: 1.25rem;
  }
  .left.eight .sepa-row .text {
    color: var(--color-gray-0);
  }
  .right.eight {
    order: 7;
    background-size: 80% auto;
    background-position: center 0;
    background-color: var(--color-pink-0);
    animation: unset;
    height: 17rem;
  }
  .left {
    height: unset;
  }
  .right {
    height: 25rem;
    background-size: 100% auto;
    background-position: unset;
  }
  .left,
  .right {
    position: unset;
    animation: unset;
    width: 100%;
  }
  .landing .content-block,
  .left.four .content-block {
    max-width: 20rem;
    margin: auto;
  }
  .left.two .text-box {
    margin: 2rem 3rem;
    display: flex;
    flex-direction: column;
    max-width: unset;
  }
  .left.two.active .text.thin,
  .left.two .text.thin {
    margin-top: 3rem;
    position: relative;
    overflow: visible;
    color: var(--color-gray-new-1);
  }
  .left.two.active .text.thin::before,
  .left.two .text.thin::before {
    position: absolute;
    content: " ";
    left: 0;
    top: -0.75rem;
    height: 4px;
    width: 3rem;
    background: var(--color-gray-new-1);
  }
  .left.three .title,
  .left.four .title,
  .left.five .title,
  .left.six .title,
  .left.seven .title,
  .left.eight .title {
    opacity: 1;
    transition: unset;
  }
  .left.three :not(.title),
  .left.four :not(.title),
  .left.five :not(.title),
  .left.six :not(.title),
  .left.seven :not(.title),
  .left.eight :not(.title) {
    opacity: 1;
    transition: unset;
  }

  .mobile-footer {
    order: 15;
    display: flex;
    flex-direction: column;
    background: var(--color-gray-new-2);
    color: var(--color-gray-7);
  }
  .mobile-footer .balett {
    width: 100vw;
    height: auto;
  }
  .mobile-footer .content-block {
    padding: 3rem 1.625rem;
    width: 100%;
    max-width: unset;
    display: flex;
    flex-direction: column;
  }
  .mobile-footer .content-block .content-header {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    max-width: 16rem;
    margin-left: 0;
    margin-right: auto;
  }
  .mobile-footer .content-block .content-header .logo .cls-1,
  .mobile-footer .content-block .content-header .logo .cls-2 {
    font-family: "Gilroy";
    fill: var(--color-gray-7);
    stroke: var(--color-gray-7);
    stroke-width: 0.5px;
  }
  .mobile-footer .content-block .text.size-20 {
    font-size: 20px;
  }
  .mobile-footer .content-block a {
    color: var(--color-gray-7);
  }
  .mobile-footer .content-block .article {
    margin-bottom: 2.375rem;
    margin-top: 0.75rem;
    font-size: 15px;
    line-height: 20px;
  }
  .mobile-footer .content-block .sm-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0;
    transform: translateX(-0.5rem);
  }
  .mobile-footer .content-block .sm-icons .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .mobile-footer .content-block .sm-icons .icon + .icon {
    margin-left: 0.75rem;
  }
  .mobile-footer .content-block .sm-icons .icon .cls-1 {
    stroke-width: 0.3px;
    fill: transparent;
  }
  .mobile-footer .content-block .sm-icons .icon .cls-2 {
    fill: var(--color-gray-7);
    stroke: var(--color-gray-0);
    stroke-width: 0.3px;
  }
  .mobile-footer .content-block .sepa {
    margin-top: 1.25rem;
    border-top: 4px solid var(--color-gray-new-1);
    width: 1.5rem;
  }

  .landing .size-28 {
    font-size: 28px;
  }
  /* CARD RULES */
  .mobile-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }
  .mobile-card .plus-sign,
  .mobile-card .minus-sign {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
  }
  .mobile-card .plus-sign .cls-1,
  .mobile-card .plus-sign .cls-1,
  .mobile-card .minus-sign .cls-2,
  .mobile-card .minus-sign .cls-2 {
    stroke: var(--color-gray-7);
    stroke-width: 1px;
  }
  .mobile-card.inverse .plus-sign .cls-1,
  .mobile-card.inverse .plus-sign .cls-1,
  .mobile-card.inverse .minus-sign #Path_5569,
  .mobile-card.inverse .minus-sign #Path_5569 {
    stroke: var(--color-gray-0);
    stroke-width: 1px;
  }
  .mobile-card .plus-sign,
  .mobile-card.toggled .minus-sign {
    display: block;
  }
  .mobile-card .minus-sign,
  .mobile-card.toggled .plus-sign {
    display: none;
  }
  .mobile-card .text {
    font-size: 20px;
  }
  .mobile-card.inverse .text {
    color: var(--color-gray-0);
  }
  .mobile-card.one:not(.toggled) + .left.two,
  .mobile-card.one:not(.toggled) ~ .right.two,
  .mobile-card.two:not(.toggled) + .left.three,
  .mobile-card.two:not(.toggled) ~ .right.three,
  .mobile-card.three:not(.toggled) ~ .left.four,
  .mobile-card.four:not(.toggled) + .left.five,
  .mobile-card.four:not(.toggled) ~ .right.five,
  .mobile-card.five:not(.toggled) + .left.six,
  .mobile-card.five:not(.toggled) ~ .right.six,
  .mobile-card.six:not(.toggled) + .left.seven,
  .mobile-card.six:not(.toggled) ~ .right.seven,
  .mobile-card.seven:not(.toggled) + .left.eight,
  .mobile-card.seven:not(.toggled) ~ .right.eight {
    max-height: 0;
    overflow: hidden;
    transition: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .mobile-card.one.toggled + .left.two,
  .mobile-card.one.toggled ~ .right.two,
  .mobile-card.two.toggled + .left.three,
  .mobile-card.two.toggled ~ .right.three,
  .mobile-card.four.toggled + .left.five,
  .mobile-card.four.toggled ~ .right.five,
  .mobile-card.five.toggled + .left.six,
  .mobile-card.five.toggled ~ .right.six,
  .mobile-card.six.toggled + .left.seven,
  .mobile-card.six.toggled ~ .right.seven,
  .mobile-card.seven.toggled ~ .right.eight {
    max-height: 30rem;
    transition: max-height 0.3s ease-in-out;
  }
  .mobile-card.two.toggled + .left.three,
  .mobile-card.two.toggled ~ .right.three {
    max-height: 35rem;
  }
  .mobile-card.two.toggled ~ .right.three {
    max-height: 25rem;
  }
  .mobile-card.three.toggled ~ .left.four,
  .mobile-card.seven.toggled + .left.eight {
    max-height: 70rem;
  }

  .mobile-floating {
    display: flex;
    position: fixed;
    bottom: 0.5rem;
    z-index: 1000;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background: var(--color-gray-0);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .mobile-floating.active {
    opacity: 1;
  }
  .mobile-floating .arrow {
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(-90deg);
    margin: auto;
  }
  .mobile-floating .arrow .cls-1 {
    fill: transparent;
    stroke-width: 6px;
    stroke: var(--color-gray-7);
  }
  .mobile-floating .arrow .cls-1 {
    stroke: var(--color-gray-7);
  }
  .mobile-floating .arrow .cls-2 {
    stroke-width: 6px;
    stroke: var(--color-gray-7);
  }

  /* DON NOT DISPLAYS */
  .left.one .double-arrow-down,
  .left.one .size-12.thin,
  .left.two.active .separator,
  .left.three .title,
  .left.four .title,
  .left.five .title,
  .left.six .title,
  .left.seven .title,
  .left.eight .title,
  .left.eight .sepa-row hr,
  .dots,
  .right.four,
  .right.six .content-block,
  .social-media-bar {
    display: none;
  }
}
