.header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
  align-items: center;
  padding: 0 20rem;
  background: transparent;
}

.camp-subtitle {
  color: var(--color-blue-1) !important;
  margin-top: 0 !important;
}

.header {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 31.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-color: var(--color-green-0);
  background-image: url("../img/teachers/header.svg");
}

.state-location {
  text-align: center;
  z-index: 1;
  margin-top: 10rem;
  color: var(--color-gray-0);
  padding-bottom: 0.5rem;
  border-bottom: 2px dotted var(--color-gray-0);
}

.state-location a {
  color: unset;
  text-decoration: unset;
}

.camp-title {
  width: 100%;
  text-align: center;
  margin-top: 8.875rem;
  margin-bottom: 4rem;
  color: var(--color-gray-8);
}

.header-text {
  width: 100%;
  text-align: center;
  padding-top: 5.75rem;
  margin-top: 0;
  color: var(--color-gray-0);
  z-index: 123;
}

.teachers-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1.325rem;
}

.teachers-wrapper .teacher-image {
  width: 30px;
  height: 30px;
  border-radius: 5rem;
  margin: 0 0.2rem;
}

.wrapper {
  background-color: var(--color-pink-0);
}

.header-title {
  height: 10.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 0.0625rem; */
}

.title h1 {
  padding-top: 4rem;
  color: #fff;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 7rem;
  height: 2.125rem;
  margin-bottom: 1.0625rem;
  border: none;
  background-color: #fff;
  color: var(--color-pink-0);
  cursor: pointer;
  font-weight: bold;
}

.info .info-item {
  display: flex;
  justify-content: space-between;
}

.info .column-first {
  min-width: 7.5rem;
}
.info .column-second {
  min-width: 9rem;
}
.info .column-third {
  min-width: 16rem;
}

.info ul {
  position: relative;
}

.info-item-prop {
  width: 7.5rem;
}

.info h4 {
  padding-top: 2.25rem;
  padding-left: 1.4375rem;
}

.active-header-tab {
  border-bottom: 4px solid var(--color-green-1);
  opacity: 1 !important;
}

.inactive-tab {
  display: none !important;
}

.info-box {
  min-height: 180px;
  width: 58rem;
  margin: 0 auto;
  margin-top: 7.25rem;
  margin-bottom: 7rem;
  background-color: #fff;
}

.info-box-header {
  display: flex;
  justify-content: center;
}

.info-box-header h3 {
  margin: 2rem 1rem;
  cursor: pointer;
  color: var(--color-gray-7);
  opacity: 0.33;
  transition: opacity 0.3s ease-in-out;
}

.info-box ul {
  margin-top: 0;
  padding-left: 2.3125rem;
}

.info {
  padding: 0 11.25rem;
}

.info ul li {
  list-style: none;
  margin-bottom: 0.2rem;
}

.places {
  min-width: 26rem;
  padding: 0 10rem;
}

.place-date p {
  width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.place-appointment {
  width: 275px;
}

.address p {
  width: 250px;
}

.places p {
  margin: 0;
}

.place-item-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;
}

.places .address {
  color: #b2b2b2;
  margin-bottom: 1.5rem;
  margin-top: 4px;
}

.page-footer {
  height: 17rem;
  background-color: var(--color-gray-0);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.footer-text {
  margin-top: 0;
  margin-bottom: 2rem;
}

.footer-buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer-buttons-wrapper a {
  padding: 0 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 7rem;
  height: 2.125rem;
  margin-bottom: 1.0625rem;
  margin-right: 1rem;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;
}

.footer-buttons-wrapper button {
  padding: 0 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 7rem;
  height: 2.125rem;
  margin-bottom: 1.0625rem;
  margin-right: 1rem;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;
}

.footer-button-details {
  border: 2px solid var(--color-btn-normal-green);
  background-color: #fff;
  color: var(--color-btn-normal-green);
}

.footer-button-details:hover {
  border: 2px solid var(--color-btn-hover-green);
  color: var(--color-btn-hover-green);
}

.footer-button-details:active {
  outline: none;
  border: 2px solid var(--color-btn-active-green);
  color: var(--color-btn-active-green);
}

.footer-button-sign-up:hover {
  background-color: var(--color-btn-hover-green);
}

.footer-button-sign-up:active {
  outline: none;
  background-color: var(--color-btn-active-green);
}

.footer-button-sign-up {
  border: none;
  background-color: var(--color-green-1);
  color: #fff;
}

.card-wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-color: #fff;
  align-items: center;
}

.card-wrapper ul p {
  margin-top: 0;
}

.text {
  width: 50%;
  padding: 0 10%;
}

.reverse {
  flex-direction: row-reverse;
  align-items: center;
}

.text p {
  margin-bottom: 0;
  line-height: 1.75;
}

.text ul {
  margin-top: 1.0625rem;
}

.text h4 {
  margin-top: 2.0625rem;
}

.pic {
  max-width: 50%;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.pic-second {
  width: 50%;
}
.pic-third {
  width: 50%;
}
.pic-fourth {
  width: 50%;
}

/* .pic img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
} */

@media screen and (max-width: 767px) {
  .header {
    width: 100%;
    height: 8.75rem;
    background-color: var(--color-pink-0);
  }

  .header-text {
    padding-top: 1.875rem;
    width: 100%;
    text-align: center;
    color: var(--color-gray-0);
    z-index: 123;
  }

  .header-text {
    margin-bottom: 1rem;
  }

  .camp-title {
    margin-top: 2.875rem;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    padding: 0;
    margin: 0 0.5rem;
  }

  .info .info-item {
    display: flex;
  }

  .info .column-first,
  .info .column-second,
  .info .column-third {
    min-width: unset;
    flex: 1;
  }

  .info .column-first p,
  .info .column-second p,
  .info .column-third p {
    font-size: 3.5vw;
    white-space: nowrap;
  }

  .info h4 {
    padding-top: 2.25rem;
    padding-left: 1.4375rem;
  }

  .places {
    min-width: 100%;
    background-color: #fff;
    padding: 0;
    margin: 0 0.5rem;
  }

  .place-item-wrapper {
    display: flex;
    justify-content: unset;
  }

  .places p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.5vw;
  }

  .place-appointment {
    flex: 1;
    width: unset;
    max-width: 40vw;
    min-width: 40vw;
  }

  .info-box {
    max-width: 25.875rem;
    background-color: #fff;
    margin: 0 auto;
  }

  .info-box-header h3 {
    white-space: nowrap;
    font-size: 22px;
    margin: 1rem 0.5rem;
  }

  .place-date p {
    width: unset;
    max-width: 60vw;
    min-width: 60vw;
    padding-right: 0.5rem;
    flex: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .details {
    max-width: 79.875rem;
  }

  .card-wrapper {
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  .info-box {
    max-width: 100%;
  }

  .text {
    padding: 0 5%;
    min-width: 100%;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0rem;
  }

  .reverse .text {
    padding: 0 5%;
  }

  .text p {
    margin-bottom: 0;
  }

  .text h4 {
    margin-top: 2.0625rem;
    text-align: center;
  }

  .pic {
    min-width: 100%;
  }

  .pic img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
  }
  .footer-button-details {
    display: none;
  }
}
