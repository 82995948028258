.header {
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25.75rem;
  background-color: var(--color-blue-1);
  background-image: url("../img/programs/program_header.svg");
}

.state-location {
  text-align: center;
  z-index: 1;
  margin-top: 2.5rem;
  color: var(--color-gray-0);
  padding-bottom: 0.5rem;
}

.state-location a {
  color: unset;
  text-decoration: unset;
}

.header-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-text {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10rem;
  color: var(--color-gray-0);
}

.year {
  color: var(--color-blue-1);
  margin: 2rem 0px 2.8rem 0px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.achievement {
  margin-top: 1.5rem;
  width: 36rem;
}

.achievement p {
  margin: 0;
}

.achievement .title {
  margin: 0;
  margin-bottom: 1.5rem;
}

.achievement .name {
  text-transform: uppercase;
}

.achievement .last {
  margin-bottom: 3.125rem;
}

.img-wrapper {
  max-width: 58rem;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .header-wrapper {
    padding: unset;
    height: unset;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .header {
    background-image: unset !important;
    height: 10.825rem;
  }
  .header-text {
    margin-top: 0;
    margin-bottom: 0;
  }
  .state-location {
    display: none;
  }
  .achievement {
    margin-top: 0;
    width: unset;
    padding: 0 1.75rem;
  }
  .year {
    color: var(--color-blue-1);
    margin: 1rem 0px 1.5rem 0px;
  }
  .achievement .last {
    margin-bottom: 1.5rem;
  }
}
