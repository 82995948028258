.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 403;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:var(--color-gray-0);
  transition: opacity 0.25s ease-in-out,transform 0.75s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  opacity: 1;
  transform-origin: center center;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 3.25rem;
}

.closed {
  transform: scale(0);
  opacity: 0;
}

.close-button {
  border: none;
  background-color: #fff;
  color: var(--color-gray-new-1);
  opacity: 0.53;
  font-size: 54px;
  font-weight: 100;
  outline: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.close-button:hover,
.close-button:active {
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 402;
  background: rgba(255, 255, 255, 1);
}

.modal-body {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.video {
  width: 66.667vw;
  height: 37.500vw;
  max-height: 65vh;
  animation: show 2s forwards;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.years {
  margin-top: 4rem;
}

.year {
  margin: 0 1rem;
  color: gray;
  cursor: pointer;
}

.selected-year {
  color: black;
}
