.header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
  align-items: center;
  background: transparent;
}

.header {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 36.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #14c1ae;
}

.state-location {
  text-align: center;
  z-index: 1;
  margin-top: 10rem;
  color: var(--color-gray-0);
  padding-bottom: 0.5rem;
  position: relative;
}

.state-location::before {
  content: " ";
  left: -0.625rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 1px;
  position: absolute;
  border-top: 2px solid var(--color-gray-0);
  border-left: 2px solid var(--color-gray-0);
  transform: rotate(-45deg);
}

.state-location a {
  color: unset;
  text-decoration: unset;
}

.title {
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-bottom: 4.875rem;
  color: var(--color-gray-0);
}

.button {
  width: 7rem;
  height: 2.125rem;
  margin-bottom: 1.0625rem;
  margin-top: 2.5rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.header-title {
  padding-top: 60px;
  padding-bottom: 40px;
  color: white;
}

.course {
  text-align: center;
  margin: 0;
  color: white;
}

@media screen and (max-width: 767px) {
  .header-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background: var(--color-gray-0);
    align-items: center;
    background: transparent;
    margin-bottom: 4rem;
    padding-top: 2rem;
  }

  .header {
    background-image: none !important;
    position: absolute;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-color: #14c1ae;
  }

  .state-location {
    display: none;
    text-align: center;
    z-index: 1;
    margin-top: 0rem;
    color: var(--color-gray-0);
    padding-bottom: 0.5rem;
    border-bottom: 2px dotted var(--color-gray-0);
  }

  .course {
    display: none;
  }
}
