.entry {
  display: flex;
  justify-content: space-between;
  background: var(--color-gray-0);
  border: 1px solid var(--color-gray-2);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.active {
  border-left: 6px solid green;
}

.draft {
  border-left: 6px solid red;
}

.entry:hover {
  transition: 0.3s;
  box-shadow: #e5e5e5 0px 2px 7px 1px;
}
.icons-wrapper {
  width: 7.5rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.icon {
  width: 18px;
  height: 18px;
  fill: var(--color-gray-6);
}
.icon:hover {
  cursor: pointer;
  transition: 0.3s;
  fill: var(--color-gray-7);
}
.title-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--color-gray-6);
}
.title {
  margin-bottom: 14px;
}

/*
  switch
*/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
