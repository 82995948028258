.wrapper {
  background-color: var(--color-blue-4);
  /* margin-top: 5rem; */
}

.courses-wrapper {
  position: relative;
  padding-top: 5.75rem;
  display: flex;
  flex-direction: column;
}

.header {
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25.75rem;
  background-image: url("../img/courses/kurzus.svg");
}

.wrapper {
  z-index: 444;
}

.header-text {
  padding-top: 60px;
  padding-bottom: 40px;
  width: 100%;
  text-align: center;
  color: var(--color-gray-0);
}

.title {
  height: 10.9375rem;
  display: flex;
  justify-content: center;
  margin-top: 6.0625rem;
}

.title h1 {
  /* padding-top: 4rem;
  color: #fff; */
}

.courses {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, 17.31rem);
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.page-footer {
  height: 17rem;
  background-color: var(--color-gray-0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  margin-top: 0;
  padding-top: 5rem;
  margin-bottom: 2rem;
}

.footer-buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer-buttons-wrapper a {
  padding: 0 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 7rem;
  height: 2.125rem;
  margin-bottom: 1.0625rem;
  margin-right: 1rem;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  transition: color 0.3s ease- border 0.3s ease-in-out;
}

.footer-button-details {
  border: 1px solid var(--color-btn-normal-green);
  background-color: #fff;
  color: var(--color-btn-normal-green);
}

.footer-button-details:hover {
  border: 1px solid var(--color-btn-hover-green);
  color: var(--color-btn-hover-green);
}

.footer-button-details:active {
  outline: none;
  border: 1px solid var(--color-btn-active-green);
  color: var(--color-btn-active-green);
}

.footer-button-sign-up:hover {
  background-color: var(--color-btn-hover-green);
}

.footer-button-sign-up:active {
  outline: none;
  background-color: var(--color-btn-active-green);
}

.footer-button-sign-up {
  border: none;
  background-color: var(--color-green-1);
  color: #fff;
}

@media screen and (max-width: 767px) {
  .header {
    z-index: -1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: unset;
  }

  .courses {
    display: flex;
    flex-direction: column;
    gap: unset;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .courses-wrapper {
    position: relative;
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }

  .header-text {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: unset;
    background: var(--color-green-1);
  }

  .page-footer {
    display: none;
  }
}
