.hamburger-menu {
  display: none;
}

@media screen and (max-width: 767px) {
  .hamburger-menu {
    display: flex;
    max-height: 2.25rem;
    width: 100%;
    transition: max-height 0.3s ease-out;
  }
  .header .hamburger-menu .logo {
    display: block;
    visibility: visible;
    height: 2rem;
  }
  .header .hamburger-menu .logo .cls-1 {
    fill: var(--color-gray-0);
  }
  .header .hamburger-menu.toggled .logo .cls-1 {
    fill: var(--color-gray-7);
  }
  .hamburger-menu .menu-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .hamburger-menu.toggled {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 18px 28px;
    background: var(--color-gray-0);
    z-index: 5;
  }
  .hamburger-menu .hamburger-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .hamburger-menu.toggled .hamburger-toggle {
    align-items: flex-end;
    position: relative;
  }
  .hamburger-menu .hamburger-toggle .toggle-fragment {
    width: 1.5rem;
    height: 0;
    margin-bottom: 0.3125rem;
    /* border-top: 2px solid var(--color-gray-0); */
    border-top: 2px solid var(--color-gray-8);
  }
  .hamburger-menu.toggled .hamburger-toggle .toggle-fragment {
    border-top: 2px solid var(--color-gray-7);
  }
  .hamburger-menu .hamburger-toggle .toggle-fragment:last-child {
    margin-bottom: 0;
  }
  .hamburger-menu.toggled .hamburger-toggle .toggle-fragment {
    position: absolute;
    top: 0rem;
    margin-top: 0;
    transition: transform 0.3s linear;
  }
  .hamburger-menu.toggled .hamburger-toggle .toggle-fragment:nth-child(1) {
    transform: rotate(45deg);
  }
  .hamburger-menu.toggled .hamburger-toggle .toggle-fragment:nth-child(2) {
    transform: rotate(-45deg);
  }
  .hamburger-menu.toggled .hamburger-toggle .toggle-fragment:nth-child(3) {
    display: none;
  }
  .hamburger-menu .menu-list {
    display: none;
  }
  .hamburger-menu.toggled .menu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--color-gray-7);
    flex: 1;
  }
  .hamburger-menu.toggled .menu-list .link {
    color: var(--color-gray-7);
    text-decoration: unset;
    margin-bottom: 4vh;
    height: unset;
  }
  .hamburger-menu.toggled .menu-list .link.active {
    padding-bottom: 0.5rem;
    border-bottom: 5px solid var(--color-gray-7);
  }
  .hamburger-menu.toggled .menu-list .sm-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .hamburger-menu.toggled .menu-list .sm-icons .icon {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .hamburger-menu.toggled .menu-list .sm-icons .icon .cls-1 {
    fill: var(--color-gray-7);
    stroke: var(--color-gray-7);
    stroke-width: 0.3px;
  }
  .hamburger-menu.toggled .menu-list .sm-icons .icon .cls-2 {
    fill: var(--color-gray-0);
    stroke: unset;
  }
  .hamburger-menu.toggled .menu-list .sm-icons .icon + .icon {
    margin-left: 0.75rem;
  }
}
