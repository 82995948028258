.teacher {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
  align-items: center;
  padding: 0 25.833vw;
}
.teacher .teacher-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25.75rem;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  background-color: var(--color-pink-0);
  background-image: url("../img/teachers/teachers_header.svg");
}
.teacher .state-location {
  text-align: center;
  z-index: 1;
  margin-top: 4.5rem;
  color: var(--color-gray-0);
  padding-bottom: 0.5rem;
  position: relative;
}

.teacher .state-location::before {
  content: " ";
  left: -0.625rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 1px;
  position: absolute;
  border-top: 2px solid var(--color-gray-0);
  border-left: 2px solid var(--color-gray-0);
  transform: rotate(-45deg);
}
.teacher .state-location a {
  color: unset;
  text-decoration: unset;
}
.teacher .title {
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-bottom: 4.875rem;
  color: var(--color-gray-0);
}
.teacher .profile-picture {
  z-index: 2;
  width: 24rem;
}
.teacher .action-picture {
  width: 100%;
  margin: 5.625rem 0;
}
.teacher .columns {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 11.25rem;
}
.teacher .column-left,
.teacher .column-right {
  display: flex;
  flex-direction: column;
}
.teacher .column-left .name {
  margin-bottom: 2.5rem;
  color: var(--color-green-0);
}
.teacher .desc {
  margin-top: 2rem;
  white-space: pre-wrap;
  line-height: 1.75;
  text-align: center;
}
.teacher .column-left .details {
  white-space: pre-wrap;
  line-height: 1.75;
}
.teacher .column-right {
  padding-top: 2rem;
}
.teacher .column-right .text {
  margin-bottom: 1.25rem;
}
.teacher .column-right .text.hidden {
  display: none;
}
.teacher .column-right .list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
}
.teacher .column-right .list .list-item::before {
  content: "\2022";
  color: var(--color-pink-0);
  font-weight: bold;
  font-size: 1.5rem;
  display: inline-block;
  left: -1rem;
  position: absolute;
}
.teacher .column-right .list .list-item {
  line-height: 1.25rem;
  position: relative;
}
.teacher .column-right .list .list-item + .list-item {
  margin-top: 1rem;
}
.teacher .column-right .list .list-item:last-child {
  padding-bottom: 2.75rem;
}

@media screen and (max-width: 767px) {
  .teacher {
    padding: unset;
  }
  .teacher .teacher-header {
    height: 13rem;
    background-image: unset;
  }
  .teacher .state-location {
    display: none;
  }
  .teacher .title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .teacher .profile-picture {
    width: 12rem;
  }
  .teacher .columns {
    flex-flow: column-reverse;
    margin-top: 2.75rem;
    margin-bottom: 0;
  }
  .teacher .column-right {
    margin-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .teacher .column-right .list .list-item {
    max-width: 12.5rem;
    line-height: 1.25rem;
    position: relative;
  }
  .teacher .column-right .list .list-item + .list-item {
    margin-top: 0.5rem;
  }
  .teacher .column-right .list .list-item:last-child {
    padding-bottom: 1.125rem;
  }
  .teacher .column-left {
    padding: 1.5rem 1.5rem;
    margin: unset;
    border-bottom: 2.875rem solid var(--color-pink-0);
  }
  .teacher .column-left .name {
    display: none;
  }
}
