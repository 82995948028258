@media screen and (max-width: 767px) {
  .card-wrapper {
    width: 25.875rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  .text {
    min-width: 100%;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    margin-bottom: 1.4375rem;
  }

  .text p {
    margin-bottom: 0;
  }

  .text ul {
    margin-top: 1.0625rem;
  }

  .text h4 {
    color: var(--color-blue-1);
    margin-top: 2.0625rem;
  }

  .pic {
    min-width: 100%;
  }

  .pic img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
  }
}

.card-wrapper {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  /* flex-direction: row; */
  background-color: #fff;
}
.text {
  width: 50%;
  margin-top: 10%;
  /* margin-left: 1.4375rem;
  margin-right: 1.4375rem; */
  /* margin-bottom: 25%; */
}

.reverse {
  flex-direction: row-reverse;
}

.text p {
  margin-bottom: 0;
}

.text ul {
  margin-top: 1.0625rem;
}

.text h4 {
  color: var(--color-blue-1);
  margin-top: 2.0625rem;
}

.pic {
  width: 50%;
}

.pic img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
}
