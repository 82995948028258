.teachers {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
}

.teachers .teachers-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 31.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url("../img/teachers/header.svg");
}

.teachers .title {
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-top: 11.25rem;
  color: var(--color-gray-0);
}

.teachers .teacher-cards {
  display: grid;
  gap: 2.875rem;
  grid-template-columns: repeat(3, 17.31rem);
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.teachers .teacher-cards a {
  color: unset;
  text-decoration: unset;
}

.teachers .teacher-cards .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0;
}

.teachers .teacher-cards .card * {
  cursor: pointer;
}

.teachers .teacher-cards .card img {
  max-width: 100%;
}

.teachers .teacher-cards .card .teacher-name {
  margin-top: 1.75rem;
  color: var(--color-gray-7);
  text-align: center;
  opacity: 0.65;
}
.teachers .teacher-cards .card .teacher-desc {
  white-space: pre-wrap;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .teachers .teachers-header {
    display: none;
  }
  .teachers .title {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: unset;
    background: var(--color-pink-0);
  }
  .teachers .teacher-cards {
    display: flex;
    gap: unset;
    grid-template-columns: unset;
    flex-direction: column;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .teachers .teacher-cards .card {
    border: unset;
    background: var(--color-gray-0);
    margin-top: 0.5rem;
    padding-bottom: 2.25rem;
  }
  .teachers .teacher-cards .card img {
    width: 100%;
  }
  .teachers .teacher-cards .card .teacher-name {
    margin-top: 1.75rem;
    font-size: 24px;
  }
  .teachers .teacher-cards .card .teacher-desc {
    margin-bottom: 0;
  }
  .teachers .teacher-cards .card .dot-dot-dot {
    display: none;
  }
}
