.admin {
  display: flex;
  width: 100%;
  height: 100%;
}
.admin .login-background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient( #0CB3A1, #14C1AE), url("../img/courses/pages/ballet/1.jpg");
  background-blend-mode: color;
  opacity: 0.75;
  z-index: 2;
}
.admin .login-background.logged-in {
  width: 0;
  height: 0;
  opacity: 0;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}
.admin .login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  z-index: 2;
}
.admin .login-box.logged-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 100%;
}
.admin .admin-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: var(--color-gray-0);
  transition: opacity 0.3s ease-in-out;
}
.admin .admin-wrapper.logged-in {
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.admin .admin-wrapper.logged-in .nav-bar {
  display: flex;
  justify-content: flex-end;
  border-bottom: 2px solid var(--color-gray-2);
  padding: 1rem;
}
.admin .admin-wrapper.logged-in .content {
  display: flex;
  flex: 1;
}
.admin .admin-wrapper.logged-in .side-bar {
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: 100%;
  border-right: 2px solid var(--color-gray-2);
  padding: 2rem;
}
.admin .admin-wrapper.logged-in .stuff {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--color-gray-1);
  padding: 5rem 10vw;
  overflow-y: scroll;
  height: 92vh;
}
.admin .btn {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 800;
  transition: background 300ms ease-in-out, color 300ms ease-in-out;
  cursor: pointer;
  border: unset;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.admin .btn + .btn {
  margin-top: 2rem;
}
.admin .btn:focus {
  outline: unset;
}
.admin .btn-primary {
  background: var(--color-green-0);
  color: var(--color-gray-0);
}
.admin .btn-primary:hover {
  background: var(--color-green-1);
}
.admin .btn-primary:active {
  background: var(--color-green-1);
}
.admin .btn-secondary {
  background: transparent;
  color: var(--color-gray-6);
}
.admin .btn-secondary:hover {
  color: var(--color-gray-7);
}
.admin .btn-secondary:active {
  color: var(--color-gray-7);
}
