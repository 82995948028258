.blue {
  background-color: var(--color-blue-1);
}

.salmon {
  background-color: var(--color-pink-0);
}

.green {
  background-color: var(--color-green-0);
}

.dot-blue {
  color: var(--color-blue-1);
}

.dot-salmon {
  color: var(--color-pink-0);
}

.dot-green {
  color: var(--color-green-0);
}

.wrapper {
  display: flex;
  justify-content: center;
  width: 17.31rem;
  position: relative;
}

.image-wrapper img {
  width: 100%;
  border-radius: 1.5rem;
}

.title {
  position: absolute;
  margin-top: 3px;
  color: var(--color-gray-0);
  margin-top: 1rem;
}

.details {
  font-size: 1em;
  font-weight: 200;
  margin: 0 auto;
}

.content {
  display: flex;
  justify-content: center;
}

.buttons {
  width: 16.125rem;
  display: flex;
  margin: 20px auto 0;
  justify-content: space-between;
}

.buttons div {
  width: 7.5rem;
  height: 2.5rem;
}

.sign-up {
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  line-height: 2.3rem;
}

.sign-up:hover {
  cursor: pointer;
}

.more-details {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
}

.more-details:hover {
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.more-details span {
  font-size: 2rem;
  line-height: 44px;
}

.dot {
  color: var(--color-blue-1);
}

.link {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    height: 13rem;
    margin-bottom: 0.625rem;
  }

  .buttons {
    display: none;
  }

  .title {
    left: 2.25rem;
    top: 1.5rem;
    margin-top: 0;
    font-size: 28px;
  }

  .details {
    font-size: 1em;
    font-weight: 200;
  }
  .image-wrapper img {
    width: 100%;
    height: 13rem;
    object-fit: scale-down;
    object-position: 100%;
  }
  .image-wrapper img.yellow {
    background-color: #E4D4B0;
  }
  .image-wrapper img.pink {
    background-color: #DFC0C7;
  }
  .image-wrapper img.blue {
    background-color: #AFE5F0;
  }
  .image-wrapper img.turquoise {
    background-color: #A8DBDE;
  }
  .image-wrapper {
    width: 100%;
  }
}
