.blog-entry {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
  align-items: center;
  padding: 0 25.833vw;
}
.blog-entry .blog-entry-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25.75rem;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  background-image: url("../img/blog/entry_header.svg");
}
.blog-entry .state-location {
  text-align: center;
  z-index: 1;
  margin-top: 4.5rem;
  color: var(--color-gray-0);
  padding-bottom: 0.5rem;
  position: relative;
}
.blog-entry .state-location::before {
  content: " ";
  left: -0.625rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 1px;
  position: absolute;
  border-top: 2px solid var(--color-gray-0);
  border-left: 2px solid var(--color-gray-0);
  transform: rotate(-45deg);
}
.blog-entry .state-location a {
  color: unset;
  text-decoration: unset;
}
.blog-entry .title {
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-bottom: 5.75rem;
  color: var(--color-gray-0);
}
.blog-entry .blog-entry-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 6rem;
  background: var(--color-gray-0);
  z-index: 1;
}

.details-first {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.blog-entry .blog-entry-box .details {
  white-space: pre-wrap;
  line-height: 1.75;
}

.blog-entry .blog-entry-box .details strong {
  font-weight: 800;
}
.blog-entry img {
  width: 51.666vw;
  height: auto;
  margin-bottom: 3rem;
}

@media screen and (max-width: 767px) {
  .blog-entry {
    padding: unset;
  }
  .blog-entry .blog-entry-header {
    height: unset;
  }
  .blog-entry .state-location {
    display: none;
  }
  .blog-entry .title {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: unset;
    background: var(--color-yellow-2);
  }
  .blog-entry .blog-entry-box .details {
    margin-top: 0;
  }
  .blog-entry .blog-entry-box {
    padding: 2rem;
    align-items: flex-start;
    padding-bottom: 3rem;
    border-bottom: 2.5rem solid var(--color-yellow-2);
  }
  .blog-entry .blog-entry-box .date,
  .blog-entry .blog-entry-box .details-header {
    display: none;
  }
  .blog-entry img {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 2.5rem solid var(--color-yellow-2);
  }
}
