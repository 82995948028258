.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-blue-1);
  align-items: center;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  background-image: url("../img/contact_header.svg");
}

.contact .contact-content,
.contact .faq {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  max-width: 80rem;
  padding: 3rem 4.25rem;
}

.contact .contact-content {
  padding: 0 18.833vw;
  max-width: unset;
  margin: 9.75rem 0;
}

.contact .faq {
  background: var(--color-gray-0);
  text-align: center;
  max-width: unset;
  padding: 7rem 18vw;
}

.contact .faq .drop-down-list {
  margin-top: 5rem;
  text-align: left;
}
.contact .faq .drop-down-list,
.contact .faq .drop-down-list .question,
.contact .faq .drop-down-list .question .question-informations{
  display: flex;
  flex-direction: column;
}
.contact .faq .drop-down-list .question {
  padding: 0.5rem 0;
  padding-left: 3rem;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.contact .faq .drop-down-list .question + .question {
  margin-top: 0.5vh;
}
.contact .faq .drop-down-list .question *{
  cursor: pointer;
}
.contact .faq .drop-down-list .question .plus-sign,
.contact .faq .drop-down-list .question .minus-sign {
  position: absolute;
  left: 1rem;
  top: 0.375rem;
  width: 1.5rem;
  height: 2rem;
  display: block;
}
.contact .faq .drop-down-list .question .minus-sign {
  display: none;
}
.contact .faq .drop-down-list .question.active .minus-sign {
  display: block;
}
.contact .faq .drop-down-list .question.active .plus-sign {
  display: none;
}
.contact .faq .drop-down-list .question .plus-sign .cls-1,
.contact .faq .drop-down-list .question .plus-sign .cls-1,
.contact .faq .drop-down-list .question .minus-sign .cls-2,
.contact .faq .drop-down-list .question .minus-sign .cls-2 {
  stroke: var(--color-gray-7);
  stroke-width: 1px;
}
.contact .faq .drop-down-list .question .question-label {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 28px;
}
.contact .faq .drop-down-list .question .question-informations {
  max-height: 0;
  overflow: hidden;
}
.contact .faq .drop-down-list .question .question-informations .answer{
  margin: 1rem 0;
  line-height: 28px;
}
.contact .faq .drop-down-list .question .question-address {
  margin-top: 0.625rem;
  margin-bottom: 1rem;
}
.contact .faq .drop-down-list .question .title {
  margin-top: 0.25rem;
}
.contact .faq .drop-down-list .question.active .question-informations {
  max-height: 30rem;
  transition: max-height 0.3s ease-in-out;
}

.contact .contact-content .contact-columns {
  display: flex;
  flex-direction: row;
}
.contact .contact-content .contact-columns .contact-box {
  display: flex;
  flex-direction: column;
}
.contact .contact-content .contact-columns .contact-box + .contact-box {
  margin-left: 7.625vw;
}
.contact .contact-content .contact-columns .contact-box *:not(button) {
  color: var(--color-gray-0);
}
.contact .contact-content .contact-columns .contact-box img {
  width: 22.656vw;
  transform: rotateY(180deg);
  margin-bottom: 7.75rem;
}
.contact .contact-content .contact-columns .contact-box .send-mail-form {
  display: flex;
  flex-direction: column;
}
.contact .contact-content .contact-columns .contact-box .send-mail-form.hidden{
  display: none;
}
.contact .contact-content .contact-columns .contact-box .form-row-input {
  margin-top: 3rem;
  line-height: 2.5rem;
  color: var(--color-gray-0);
  background: transparent;
  border: 0;
  outline: 0;
  border-bottom: 4px solid var(--color-gray-0);
}
.contact .contact-content .contact-columns .contact-box .form-row-input:nth-child(4) {
  margin-bottom: 3rem;
}
.contact .contact-content .contact-columns .contact-box .form-row-input::placeholder {
  color: var(--color-gray-0);
  opacity: 0.58;
}
.contact .contact-content .contact-columns .contact-box .checkbox-row {
  cursor: pointer;
  padding-left: 2.5rem;
}
.contact .contact-content .contact-columns .contact-box .checkbox-row input {
  cursor: pointer;
  width: 1px;
  height: 1px;
  position: relative;
  appearance: none;
  outline: none;
}
/* hack autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}
.contact .contact-content .contact-columns .contact-box .checkbox-row input::before {
  position: absolute;
  content: '';
  opacity: 0;
  width: 2px;
  height: 0.5rem;
  top: -0.5625rem;
  left: -2.0625rem;
  border-right: 2px solid var(--color-gray-0);
  border-bottom: 2px solid var(--color-gray-0);
  transform: rotate(38deg);
  transition: opacity 0.3s ease-in-out;
}
.contact .contact-content .contact-columns .contact-box .checkbox-row input:checked::before {
  opacity: 1;
}
.contact .contact-content .contact-columns .contact-box .checkbox-row input::after {
  position: absolute;
  content: ' ';
  width: 0.75rem;
  height: 0.75rem;
  top: -0.75rem;
  left: -2.5rem;
  border: 3px solid var(--color-gray-0);
}
.contact .contact-content .contact-columns .contact-box .checkbox-row span{
  opacity: 0.58;
  user-select: none;
}
.contact .contact-content .contact-columns .contact-box .contact-row {
  display: block;
  margin-top: 0.75rem;
}
.contact .contact-content .contact-columns .contact-box .content-title {
  margin-bottom: 2rem;
}
.contact .contact-content .contact-columns .contact-box .content-text {
  line-height: 28px;
}
.contact .contact-content .contact-columns .contact-box .content-text a{
  text-decoration: none;
}
.contact .contact-content .btn {
  margin-top: 3rem;
  margin-left: auto;
  padding: 15px 24px;
  font-size: 15px;
  font-weight: 800;
  border: 0;
  background: var(--color-gray-0);
  color: var(--color-btn-normal-blue);
  transition: color 0.3s ease-in-out;
}
.contact .contact-content .btn-primary:hover {
  color: var(--color-btn-hover-blue);
  background: var(--color-gray-0);
}
.contact .contact-content .btn-primary:active {
  color: var(--color-btn-active-blue);
  background: var(--color-gray-0);
}
.contact .contact-content .btn:focus {
  outline: unset;
}
@media screen and (max-width: 767px) {
  .contact {
    background-image: none;
  }
  .contact .contact-content {
    display: flex;
    gap: unset;
    grid-template-columns: unset;
    flex-direction: column;
    margin: 0;
    padding: 1.75rem 1rem;
    padding-bottom: 1.375rem;
    padding-top: 5rem;
  }
  .contact .contact-content .contact-columns .contact-box img {
    width: 12rem;
    margin-right: auto;
    margin-left: unset;
    margin-bottom: 2rem;
  }
  .contact .contact-content .contact-columns .contact-box .content-title {
    width: 50vw;
  }
  .contact .contact-content .contact-columns {
    flex-flow: column-reverse;
  }
  .contact .contact-content .contact-columns .contact-box {
    margin-bottom: 2rem;
  }
  .contact .contact-content .contact-columns .contact-box + .contact-box {
    margin-left: 0;
  }
  .contact .faq {
    padding: 2rem;
  }
  .contact .faq .drop-down-list {
    margin-top: 3rem;
  }
  .contact .faq .drop-down-list .question + .question {
    margin-top: 0;
  }
}
