.wrapper {
  background-color: var(--color-gray-new-2);
  color: var(--color-gray-7);
  padding-bottom: 2rem;
  padding-top: 3rem;
}

.icons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 6rem;
  margin-left: 6rem;
  margin-bottom: 3rem;
}

.text-wrapper p {
  margin: 0;
  margin-bottom: 6px;
  margin-left: 1rem;
}

.social-media {
  display: flex;
  align-items: center;
  transform: translateX(-10px);
}

.social-insta {
  margin-right: 0.75rem;
}

.social-fb {
  margin-right: 0.75rem;
}

.details-box {
  max-width: 16.667vw;
  min-width: 16.667vw;
}

.details-box p {
  white-space: nowrap;
}

.details-box a {
  color: var(--color-gray-7);
}

.details-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 6rem;
  margin-left: 6rem;
}

.details-wrapper + .details-wrapper {
  justify-content: flex-end;
}

.details-wrapper .sepa {
  margin-top: 1.25rem;
  border-top: 4px solid var(--color-gray-new-1);
  width: 1.5rem;
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: none;
    /* display: flex;
    flex-direction: column; */
  }
}
