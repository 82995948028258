.header-banner {
  width: 100%;
  min-height: 4rem;
  background-color: yellow;
  position: absolute;
  top: 6rem;
  z-index: 5;
  display: flex;
  justify-content: space-between;
}

.banner-title {
  width: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-right: 2px solid gray;
}

.banner-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.banner-close {
  border: none;
  height: 3rem;
  background-color: transparent;
  color: var(--color-gray-new-1);
  opacity: 0.53;
  font-size: 60px;
  font-weight: 100;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-left: 1rem;
  margin-right: 2rem;
  box-shadow: unset;
  transition: opacity 0.3s ease-in-out;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 401;
  padding: 1.625rem 2.75rem;
}
.header.background-green {
  background: var(--color-green-0);
}
.header.background-green-1 {
  background: var(--color-green-1);
}
.header.background-green-2 {
  background: var(--color-green-3);
}
.header.background-blue-1 {
  background: var(--color-blue-1);
}
.header.background-blue-4 {
  background: var(--color-blue-4);
}
.header.background-yellow {
  background: var(--color-yellow-2);
}
.header.background-pink {
  background: var(--color-pink-0);
}
/* LANDING RULES!!! */
.header.background-pink.fixed,
.header.background-erohw.fixed,
.header.background-green-1.fixed {
  background: transparent;
}
.header.background-erohw.fixed .links a {
  background: transparent;
  color: var(--color-gray-7);
}
/*END OF LANDING RULES*/

/* FIXED RULES!!! */
.header.background-green.fixed,
.header.background-blue-1.fixed {
  background: transparent;
}
.header.background-blue-2.fixed {
  background: var(--color-blue-1);
}
/*END OF FIXED RULES*/
.header.fixed {
  top: 0;
  left: 0;
  position: absolute;
}

.header .logo {
  height: 1.667vw;
  transition: height 0.3s linear;
}
.header .logo .cls-1 {
  /* fill: var(--color-gray-7); */
  fill: var(--color-gray-0);
  transition: fill 0.3s linear;
  stroke: unset;
}
.header .logo-white .cls-1 {
  fill: var(--color-gray-0);
}
.header .logo.inverse .cls-1 {
  fill: var(--color-gray-7);
  stroke: unset;
}
.header.big .logo {
  height: 2.917vw;
}

.header .links {
  display: flex;
  position: relative;
}
.header .links a,
.header .links label.plus-sign {
  text-decoration: none;
  font-size: 0.829vw;
  font-weight: 800;
  color: var(--color-gray-0);
  white-space: nowrap;
  height: 1.375rem;
}
@media screen and (max-width: 1600px) {
  .header .links a,
  .header .links label.plus-sign {
    font-size: 0.85vw;
  }
}
@media screen and (max-width: 1440px) {
  .header .links a,
  .header .links label.plus-sign {
    font-size: 0.9vw;
  }
}
.header .links a.active,
.header .links label.active.plus-sign {
  border-bottom: 3px solid var(--color-gray-0);
}
.header.text-inverse .links a,
.header.text-inverse .links label.plus-sign {
  color: var(--color-gray-7);
}
.header.text-inverse .links a.active,
.header.text-inverse .links label.active.plus-sign {
  border-bottom: 3px solid var(--color-gray-7);
}
.header .links .dropdown-menu {
  position: absolute;
  transition: opacity 300ms ease-in, transform 300ms ease-in;
  flex-direction: column;
  padding: 1.5rem 1.875rem;
  top: 1.425rem;
  opacity: 0;
  background: var(--color-gray-0);
  transform: scaleY(0) translateX(-38%);
  transform-origin: top;
}
.header .links label:hover .dropdown-menu {
  display: flex;
  z-index: 10;
  opacity: 1;
  transform: scaleY(1) translateX(-38%);
}
.header .links label.programs .dropdown-menu {
  transform: scaleY(0) translateX(-30%);
}
.header .links label.programs:hover .dropdown-menu {
  transform: scaleY(1) translateX(-30%);
}
.header .links .dropdown-menu .menu-row {
  display: flex;
}
.header .menu-item {
  margin-left: 0;
}
.header .links .dropdown-menu .menu-row + .menu-row {
  margin-top: 1rem;
}
.header .links .dropdown-menu .menu-row a {
  display: flex;
  flex-direction: column;
  height: unset;
  width: 7.5rem;
  cursor: pointer;
}
.header .links .dropdown-menu .menu-row a + a {
  margin-left: 1.875rem;
}
.header .links .dropdown-menu .menu-row a span {
  color: var(--color-gray-7);
}
.header .links a + a {
  margin-left: 0.833vw;
}
.header .links label + a {
  margin-left: 1rem;
}
.header .links .plus-sign {
  position: relative;
  margin-left: 1.5rem;
}
.header .links .plus-sign::before {
  content: "+";
  position: absolute;
  left: -0.75rem;
}

@media screen and (max-width: 767px) {
  .banner-title {
    display: none;
  }
  .header {
    position: unset;
    padding: 18px 28px;
    margin: 0;
    width: 100%;
    height: unset;
  }
  .header.big .logo {
    height: 2rem;
  }
  .header.fixed {
    position: unset;
  }
  .header.fixed.text-inverse {
    position: absolute;
  }
  /* LANDING RULE!!! */
  .header.background-gray-erohw.fixed {
    background: transparent;
  }
  .header.background-green.fixed {
    background: var(--color-green-0);
  }
  /* green 1 sucks*/
  .header.background-green-1.fixed {
    background: var(--color-green-0);
  }
  .header.background-blue-1.fixed {
    background: var(--color-blue-1);
  }
  .header.background-yellow.fixed {
    background: var(--color-yellow-2);
  }
  .header.background-pink.fixed {
    background: var(--color-pink-0);
  }
  /*END OF LANDING RULE*/
  .header a {
    height: 2rem;
  }
  .header .logo,
  .header .links {
    display: none;
  }
}
