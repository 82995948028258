.blog {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
  align-items: center;
}
.blog .blog-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25.75rem;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  background-image: url("../img/blog/entry_header.svg");
}

.blog .title {
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-top: 5.75rem;
  margin-bottom: 8.75rem;
  color: var(--color-gray-0);
}

.blog .blog-cards {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.blog .blog-cards a {
  color: unset;
  text-decoration: unset;
}

.blog .blog-cards .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--color-gray-1);
  height: 22rem;
  margin-bottom: 7.5rem;
  max-width: 58rem;
}

.blog .blog-cards .card .date-for-mobile {
  display: none;
}

.blog .blog-cards .card * {
  cursor: pointer;
}

.blog .blog-cards .card .picture-box {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  max-width: 40rem;
  min-width: 50%;
}
.blog .blog-cards .card .picture-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.blog .blog-cards .card .picture-box .picture-title {
  position: absolute;
  left: 4rem;
  bottom: 5.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  background: var(--color-yellow-2);
}
.blog .blog-cards .card .text-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-width: 50%;
  max-width: 40rem;
  padding: 3.25rem 4rem;
  position: relative;
}
.blog .blog-cards .card .text-box .blog-entry-text {
  line-height: 1.25rem;
  overflow: hidden;
  white-space: pre-wrap;
}
.blog .blog-cards .card .text-box .blog-entry-date {
  margin-bottom: 2.125rem;
}
.blog .blog-cards .card .text-box .blog-entry-desc {
  margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .blog .blog-header {
    display: none;
  }
  .blog .title {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: unset;
    background: var(--color-yellow-2);
  }
  .blog .blog-cards {
    display: flex;
    gap: unset;
    grid-template-columns: unset;
    flex-direction: column;
    margin: 0;
    padding: 0 0.625rem;
    padding-bottom: 2.5rem;
  }
  .blog .blog-cards .card {
    flex-direction: column;
    height: unset;
    margin-bottom: 1rem;
  }
  .blog .blog-cards .card .date-for-mobile {
    display: block;
  }
  .blog .blog-cards .card .picture-box {
    display: flex;
    flex: unset;
    width: 100%;
    height: 16.875rem;
    position: relative;
    max-width: unset;
    min-width: unset;
  }
  .blog .blog-cards .card .picture-box .picture-title {
    left: 1.5rem;
    bottom: 1.5rem;
    font-size: 21px;
  }
  .blog .blog-cards .card .text-box {
    display: flex;
    flex: unset;
    flex-direction: column;
    width: 100%;
    height: unset;
    min-width: unset;
    max-width: unset;
    padding: 1.875rem 1.5rem;
    padding-bottom: 3rem;
    position: relative;
  }
  .blog .blog-cards .card .text-box .blog-entry-desc {
    font-size: 24px;
  }
  .blog .blog-cards .card .text-box .blog-entry-text {
    font-size: 13px;
    height: 2.625rem;
    line-height: 1.375rem;
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
  .blog .blog-cards .card .text-box .blog-entry-date {
    display: none;
  }
}
