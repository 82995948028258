:root {
  --color-blue-0: #dbf3ff;
  --color-blue-1: #19b3fc;
  --color-blue-2: #0582be;
  --color-blue-3: #2f74e3;
  --color-blue-4: #1362e1;
  --color-blue-5: #0f25e6;
  --color-gray-0: #ffffff;
  --color-gray-1: #f8fafa;
  --color-gray-2: #f0f0f0;
  --color-gray-3: #e4eaea;
  --color-gray-4: #bfbec1;
  --color-gray-5: #d9d9d9;
  --color-gray-6: #707070;
  --color-gray-7: #141414;
  --color-gray-8: #000000;
  --color-gray-new-0: #b9c1c7;
  --color-gray-new-1: #333333;
  --color-gray-new-2: #f8f9f9;
  --color-green-0: #14c1ae;
  --color-green-1: #0cb3a1;
  --color-green-2: #59bbac;
  --color-green-3: #92d9d1;
  --color-pink-0: #f4a7b7;
  --color-pink-1: #ea728b;
  --color-yellow-0: #ffe2a8;
  --color-yellow-1: #fed847;
  --color-yellow-2: #fdc453;
  --color-yellow-3: #cb972f;
  --color-yellow-4: #fc5819;

  --color-btn-normal-blue: var(--color-blue-1);
  --color-btn-hover-blue: #39bdfc;
  --color-btn-active-blue: #6bcefd;
  --color-btn-normal-green: var(--color-green-1);
  --color-btn-hover-green: #2ebdae;
  --color-btn-active-green: #63cec2;
  --color-btn-normal-green-2: #ffffff;
  --color-btn-hover-green-2: #dcf4f1;
  --color-btn-active-green-2: #a7e3dd;
}
