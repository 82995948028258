.operation {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: var(--color-gray-0);
  align-items: center;
}
.operation .operation-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 31.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url("../img/operation/operation_header.jpg");
}

.operation .title {
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-top: 5.75rem;
  margin-bottom: 8.75rem;
  color: var(--color-gray-0);
  padding-top: 30px;
  padding-bottom: 30px;
}

.operation .operation-content {
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  max-width: 59.25rem;
  padding: 3.25rem 4.25rem;
  background: var(--color-gray-0);
}

.operation .operation-content .drop-down-list,
.operation .operation-content .drop-down-list .info,
.operation .operation-content .drop-down-list .info .info-informations {
  display: flex;
  flex-direction: column;
}
.operation .operation-content .drop-down-list .info {
  padding: 1rem 0;
  padding-left: 4.25rem;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.operation .operation-content .drop-down-list .info.active {
  height: unset;
}
.operation .operation-content .drop-down-list .info * {
  cursor: pointer;
}
.operation .operation-content .drop-down-list .info-title {
  display: flex;
}
.operation .operation-content .drop-down-list .info .plus-sign,
.operation .operation-content .drop-down-list .info .minus-sign {
  width: 1.979vw;
  height: 1.979vw;
  display: block;
}
.operation .operation-content .drop-down-list .info .plus-sign.small,
.operation .operation-content .drop-down-list .info .minus-sign.small {
  width: 1.875rem;
  height: 1.875rem;
  margin: 0 0.25rem;
}
.operation .operation-content .drop-down-list .info .minus-sign {
  display: none;
}
.operation .operation-content .drop-down-list .info.active .minus-sign {
  display: block;
}
.operation .operation-content .drop-down-list .info.active .plus-sign {
  display: none;
}
.operation .operation-content .drop-down-list .info .plus-sign .cls-1,
.operation .operation-content .drop-down-list .info .plus-sign .cls-1,
.operation .operation-content .drop-down-list .info .minus-sign .cls-2,
.operation .operation-content .drop-down-list .info .minus-sign .cls-2 {
  stroke: var(--color-gray-7);
  fill: var(--color-gray-7);
}
.operation
  .operation-content
  .drop-down-list
  .info.primary
  ~ .info:not(.primary) {
  display: none;
}
.operation .operation-content .drop-down-list .info.active.primary ~ .info {
  display: flex;
}
.operation .operation-content .drop-down-list .info .info-informations {
  max-height: 0;
  overflow: hidden;
}
.operation .operation-content .drop-down-list .info.active .info-informations {
  display: block;
  max-height: 150rem;
  white-space: pre-wrap;
  padding-top: 1.3125rem;
  padding-bottom: 2.6875rem;
  padding-left: 2.5rem;
  transition: max-height 0.3s linear;
  line-height: 28px;
}
.operation .operation-content .drop-down-list .info .info-informations.hidden {
  padding: 0;
}
.operation .operation-content .downloads {
  margin: 5rem 0;
  text-align: center;
}
.operation .operation-content .download-title {
  margin-bottom: 3.125rem;
  margin-left: 4.25rem;
}
.operation .operation-content .label-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-left: 4.25rem;
  text-decoration: none;
  color: var(--color-gray-7);
}
.operation .operation-content .label-with-icon .download-icon {
  width: 1.4375rem;
  height: 1.4375rem;
}
.operation .operation-content .label-with-icon.margin-bottom {
  margin-bottom: 4rem;
}
.operation .operation-content .label-with-icon .download-text {
  margin-left: 1.75rem;
  cursor: pointer;
}
.operation .operation-content .download-icon .cls-1 {
  fill: var(--color-green-0);
}

@media screen and (max-width: 767px) {
  .operation {
    background: var(--color-green-0);
  }
  .operation .operation-header {
    display: none;
  }
  .operation .title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .operation .operation-content {
    display: flex;
    gap: unset;
    grid-template-columns: unset;
    flex-direction: column;
    margin: 0;
    padding: 0 2rem;
    padding-bottom: 2.5rem;
  }
  .operation .operation-content .drop-down-list {
    margin-top: 1rem;
  }
  .operation .operation-content .drop-down-list .info {
    border: 0;
    padding-left: 0;
  }
  .operation .operation-content .drop-down-list .info.primary {
    text-transform: lowercase;
  }
  .operation .operation-content .drop-down-list .info .plus-sign,
  .operation .operation-content .drop-down-list .info .minus-sign {
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
    display: block;
  }
  .operation .operation-content .drop-down-list .info .plus-sign.small,
  .operation .operation-content .drop-down-list .info .minus-sign.small {
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
  .operation .operation-content .drop-down-list .info .minus-sign,
  .operation .operation-content .drop-down-list .info .minus-sign.small {
    display: none;
  }
  .operation .operation-content .drop-down-list .info.active .minus-sign {
    display: block;
  }
  .operation .operation-content .drop-down-list .info.active .plus-sign.small {
    display: none;
  }
  .operation .operation-content .drop-down-list .info.active .info-informations {
    padding-left: 1.5rem;
  }
  .operation .operation-content .downloads {
    margin: 2.25rem 0;
    text-align: center;
  }
  .operation .operation-content .download-title {
    margin-bottom: 1.75rem;
    margin-left: 0;
  }
  .operation .operation-content .download-icon {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
  .operation .operation-content .label-with-icon {
    margin-bottom: 1.125rem;
    margin-left: 0;
  }
  .operation .operation-content .label-with-icon.margin-bottom {
    margin-bottom: 2rem;
  }
  .operation .operation-content .label-with-icon .download-text {
    margin-left: 1.25rem;
  }
}
